<template>
  <form action="/businesses" class="p-0">
    <label for="find_biz_by_id">Поиск по ID бизнеса</label>
    <input id="find_biz_by_id" class="border rounded mb-2" required maxlength="6" type="text" name="id" placeholder="Введите ID бизнеса">
    <button class="py-0 w-100 border rounded text-nowrap">Найти по ID</button>
  </form>
</template>

<script>

export default {
  name: "FindById"
};

</script>
