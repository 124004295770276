import FavoriteBusiness from './favoriteBusiness'

document.addEventListener('DOMContentLoaded', () => {
  window.Burobiz.FavoriteBusiness = new FavoriteBusiness({
    store: localStorage,
    buttonClass: 'addToFavorite',
    popupText: "Добавлено в <a href='/favorites'>избранное</a>"
  })

  window.Burobiz.FavoriteBusiness.loadCollection()
})
