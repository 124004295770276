import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menu']

  connect () {
  }

  toggle () {
    this.menuTarget.classList.toggle('d-none')
    this.menuTarget.classList.toggle('active')
  }

  hide (event) {
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('d-none')) {
      this.menuTarget.classList.add('d-none')
    }
  }

  open () {
    this.menuTarget.classList.remove('d-none')
  }
}
