export default {
  getBusinesses(params, path) {
    let query = '?'
    let category = (params.subcategory && params.subcategory.id) || params.category.id || ""
    if (this.buildQueryParams(params).length) {
      query = this.buildQueryParams(params)
    }
    return path + query + '&category=' + category
  },
  getBusinessesSize(params) {
    let query = '?'
    let category = (params.subcategory && params.subcategory.id) || params.category.id || ""
    if (this.buildQueryParams(params).length) {
      query = this.buildQueryParams(params)
    }
    return "/businesses/size.json" + query + '&category=' + category
  },
  getBusinessesFromParamsUrl(params) {
    let query = ''
    let category = (params.subcategory && params.subcategory.slug) || params.category.slug || ""
    if (category.length) category = "/" + category
    if (this.buildQueryParams(params).length) {
      query = this.buildQueryParams(params)
    }
    return "/businesses" + category + query
  },
  buildQueryParams(params) {
    let title = params.title
    let price_to = params.price_to
    let price_from = params.price_from
    let income_to = params.income_to
    let income_from = params.income_from
    let age_to = params.age_to
    let age_from = params.age_from
    let payback_to = params.payback_to
    let payback_from = params.payback_from

    let query_params = "?";

    if (params.metro_stations.length) {
      query_params += "&metro_stations=" +
        params.metro_stations
        .map(metro => {
          return metro.id;
        })
        .join("-");
    }

    if (price_to) {
      query_params += "&price_to=" + price_to;
    }
    if (price_from) {
      query_params += "&price_from=" + price_from;
    }
    if (income_to) {
      query_params += "&income_to=" + income_to;
    }
    if (income_from) {
      query_params += "&income_from=" + income_from;
    }
    if (age_to && age_to > 0) {
      query_params += "&age_to=" + age_to;
    }
    if (age_from && age_from > 0) {
      query_params += "&age_from=" + age_from;
    }
    if (payback_to && payback_to > 0) {
      query_params += "&payback_to=" + payback_to;
    }
    if (payback_from && payback_from > 0) {
      query_params += "&payback_from=" + payback_from;
    }

    query_params = query_params.replace(/\s+/g, '')

    if (title) {
      query_params += "&title=" + title;
    }

    if (query_params.length === 1) {
      query_params = ''
    }

    return query_params
  }
}
