<template>
  <div>
    <div class="popup__row" >
      <div class="popup__label" @click="popupOpen = true">
        <slot name="label"></slot>
      </div>
      <div class="popup__value">
        <div class="d-flex justify-content-between">
          <div class="popup__input d-flex justify-content-between" @click="popupOpen = true">
            <slot name="inputs_value" ></slot>
          </div>
          <slot name="reset_value"></slot>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="popupOpen" class="popup__overlay" @click="popupOpen = false"></div>
    </transition>
    <transition name="slide-up">
      <div v-if="popupOpen" class="popup">
        <div class="popup__header">
          <div class="popup__title">
            <slot name="label"></slot>
          </div>
          <button class="popup__close" @click="popupOpen = false">
            <i class="icon-close"></i>
          </button>
        </div>
        <div class="popup__box mb-3">
          <slot name="inputs"></slot>
        </div>
        <div class="popup__actions">
          <button class="popup__btn text-danger p-3">
            Сбросить
          </button>
          <button class="popup__btn text-info mr-1 p-3" @click="submitForm">
            Применить
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: "Popup",
  data() {
    return {
      popupOpen: false
    }
  },
  methods:{
    submitForm(){
      this.popupOpen = false
    }
  }
};
</script>
