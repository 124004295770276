export default {
  title: '',
  category: '',
  subcategory: '',
  price_to: '',
  price_from: '',
  income_to: '',
  income_from: '',
  payback_from: 0,
  payback_to: 0,
  age_from: 0,
  age_to: 0,
  metro_stations: []
}
