import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: String }

  connect () {
  }

  load (e) {
    e.preventDefault()

    const category = e.target.dataset.category
    const path = e.target.dataset.path

    $('.business_discount__list-item').removeClass('active')
    $(e.target).addClass('active')

    $('.business_discount__btn').attr('href', `/businesses/${category}?discount=1`)
    $.getScript(path)
  }
}
