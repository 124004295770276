import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: String }

  connect () {
  }

  call () {
    $.getScript(this.urlValue + '&remote=1')
  }
}
