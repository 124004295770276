import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: String }

  connect () {
  }

  show () {
    $('#business_preview_content').html('')
    $.getScript(this.urlValue)
  }
}
