export default class FavoriteBusiness {
  constructor ({ store, buttonClass, popupText }) {
    this.store = store
    this.buttonClass = buttonClass
    this.activeClass = 'active'
    this.popupText = popupText
    this.setClickEvent()
    this.setActiveClass()
  }

  save (business_id) {
    const businessList = this.all()
    businessList.push(business_id)
    this.store.setItem('FavoriteBusiness', JSON.stringify(businessList))
    this.updateCounterSize()
  }

  remove (id) {
    const businessList = this.all().filter(el => el != id)
    this.store.setItem('FavoriteBusiness', JSON.stringify(businessList))
    this.updateCounterSize()
  }

  all () {
    return JSON.parse(this.store.getItem('FavoriteBusiness')) || []
  }

  loadCollection () {
    const container = document.getElementById('favorite_businesses') || false
    if (container) {
      if (this.all().length > 0) {
        $.getScript('/favorites?ids=' + this.all().reverse().join('-'))
      } else {
        const empty_block = document.getElementById('favorite_businesses_empty')
        empty_block.style.display = 'block'
      }
    }
  }

  clickFavoriteBtn (event) {
    if (event.target.classList.contains(this.buttonClass)) {
      const favoriteBtn = event.target

      if (favoriteBtn.classList.contains(this.activeClass)) {
        this.remove(parseInt(favoriteBtn.getAttribute('data-id')))
        favoriteBtn.classList.remove(this.activeClass)
      } else {
        this.save(parseInt(favoriteBtn.getAttribute('data-id')))
        favoriteBtn.classList.add(this.activeClass)
        const popup = document.createElement('div')
        popup.classList.add('add_to_favorite__popup')
        popup.innerHTML = this.popupText
        favoriteBtn.appendChild(popup)
        setTimeout(() => {
          favoriteBtn.removeChild(popup)
        }, 3000)
      }
    }
  }

  updateCounterSize () {
    const counters = document.querySelectorAll('.add_to_favorite__count')
    counters.forEach(counter => counter.innerHTML = this.all().length)
  }

  setClickEvent () {
    document.addEventListener('click', event => this.clickFavoriteBtn(event))
  }

  setActiveClass () {
    this.updateCounterSize()
    Array.from(document.getElementsByClassName(this.buttonClass)).forEach(btn => {
      if (this.all().includes(parseInt(btn.getAttribute('data-id')))) {
        btn.classList.add(this.activeClass)
      }
    })
  }
}
