import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container']

  static values = {
    mode: String,
    loop: Boolean,
    scrollPerClick: { type: Number, default: 1 }
  }

  next () {
    if (this.loopValue) {
      const count = Array.from(this.containerTarget.children).length - 1
      if (this.isVisible(this.containerTarget.children[count])) {
        this.moveFirst()
      } else {
        this.containerTarget[`scroll${this.scrollMode()}`] += this.scrollSize()
      }
    } else {
      this.containerTarget[`scroll${this.scrollMode()}`] += this.scrollSize()
    }
  }

  prev () {
    if (this.loopValue) {
      if (this.isVisible(this.containerTarget.children[0])) {
        this.moveEnd()
      } else {
        this.containerTarget[`scroll${this.scrollMode()}`] -= this.scrollSize()
      }
    } else {
      this.containerTarget[`scroll${this.scrollMode()}`] -= this.scrollSize()
    }
  }

  moveFirst () {
    const count = Array.from(this.containerTarget.children).length - 1
    const scrollSize = this.containerGapSize() + this.childSize()
    this.containerTarget[`scroll${this.scrollMode()}`] -= count * scrollSize
  }

  moveEnd () {
    const count = Array.from(this.containerTarget.children).length - 1
    const scrollSize = this.containerGapSize() + this.childSize()
    this.containerTarget[`scroll${this.scrollMode()}`] += count * scrollSize
  }

  isVisible (elem) {
    if (!(elem instanceof Element)) throw Error('DomUtil: elem is not an element.')
    const style = getComputedStyle(elem)
    if (style.display === 'none') return false
    if (style.visibility !== 'visible') return false
    if (style.opacity < 0.1) return false
    if (elem.offsetWidth + elem.offsetHeight + elem.getBoundingClientRect().height +
        elem.getBoundingClientRect().width === 0) {
      return false
    }
    const elemCenter = {
      x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
      y: elem.getBoundingClientRect().top + elem.offsetHeight / 2
    }
    if (elemCenter.x < 0) return false
    if (elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)) return false
    if (elemCenter.y < 0) return false
    if (elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)) return false
    let pointContainer = document.elementFromPoint(elemCenter.x, elemCenter.y)
    do {
      if (pointContainer === elem) return true
    } while (pointContainer = pointContainer.parentNode)
    return false
  }

  scrollSize () {
    const scrollSize = this.containerGapSize() + this.childSize()
    return scrollSize * this.scrollPerClickValue
  }

  scrollMode () {
    return this.modeValue === 'vertical' ? 'Top' : 'Left'
  }

  containerGapSize () {
    const gap = window.getComputedStyle(this.containerTarget).gap.split(' ')[0]
    return Number(gap.replace(/[^0-9.]/g, ''))
  }

  childSize () {
    const sizeBy = this.modeValue === 'vertical' ? 'offsetHeight' : 'offsetWidth'
    return this.containerTarget.children[0][sizeBy]
  }
}
