import axios from 'axios'
import Endpoint from './endpoint'

export default {
  getBusinesses (cb, params) {
    const url = Endpoint.getBusinesses(params, '/businesses/filter_businesses.json')
    axios
      .get(url)
      .then((response) => { cb(response.data) })
  },
  getBusinessesRemote (cb, params) {
    const categorySlug = params.category.slug
    const url = categorySlug ? Endpoint.getBusinesses(params, '/businesses/' + categorySlug) : Endpoint.getBusinesses(params, '/businesses/')
    $.getScript(url + '&remote=1')
  },
  getBusinessesSize (cb, params) {
    const url = Endpoint.getBusinessesSize(params)
    axios
      .get(url)
      .then((response) => { cb(response.data) })
  }
}
