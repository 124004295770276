<template>
  <div>
    <div v-if="metro_stations.length && currentCity == ''">
      <div class="filter__box pt-4 border-0" @click="metroOpen = true">
        <div class="filter__label">Метро</div>
        <div class="d-flex">
          <div class="btn-main dx-flex px-2 justify-content-between w-100 align-items-center">
            <div class="d-flex align-items-center">
              <SvgIcon icon="metro" :classes="'svg-icon'" />
              <div class="pl-2">
                <template v-if="params.metro_stations.length">
                  Выбрано ({{ params.metro_stations.length }})
                </template>
                <template v-else>Не выбрано</template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div v-if="metroOpen" style="z-index:1100" class="filter filter--full filter--padding m-metro__modal">
        <div class="container pt-5 pb-5">
          <div class="pt-5">
            <div class="fixed-top bg-white p-2 border-bottom d-flex justify-content-center align-items-center">

              <p style="font-size: 25px">Карта метро</p>
              <button class="btn-default btn" @click="metroOpen = false">
                <i class="icon-close"></i>
              </button>
            </div>
            <div class="container" style="position:relative;">
              <svg style="position:absolute" viewBox="0 0 1262 1373" width="1122" xmlns="http://www.w3.org/2000/svg">
                <g v-for="metro in filteredMetros" :key="metro.id" @click="toggleMetroStationsDesktop(metro)"
                  :class="{ selected: params.metro_stations.includes(metro) }" class="metro-map__station"
                  :transform="metro.pos">
                  <text>{{ metro.name }}</text>
                </g>
              </svg>
              <img :src="metroMapSPB" width="1122" alt="metroMapSPB">
            </div>
            <div class="fixed-bottom bg-white p-3 border-top d-flex justify-content-center align-items-center">
              <div class="d-flex">
                <button class="btn-warning btn mr-2" @click="resetSelectedMetro">
                  <span>Сбросить</span>
                </button>
                <button class="btn-info btn mr-2" :class="[businesses.length > 100 ? ' ' : ' ']"
                  :disabled="!businesses.length > 100" @click="metroOpen = false">
                  Применить ({{ params.metro_stations.length }})
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import { mapActions, mapState } from "vuex"
import SvgIcon from "./SvgIcon.vue"

const metroMapSPB = window.Burobiz.Images.metroMapSPB

export default {
  name: "DesktopMetro",
  computed: {
    filteredMetros() {
      if (this.metro_search.length) {
        return this.metro_stations.filter(metro => {
          return metro.name
            .toUpperCase()
            .includes(this.metro_search.toUpperCase())
        });
      } else {
        return this.metro_stations;
      }
    },
    ...mapState([
      'params',
      'metro_lines',
      'metro_stations',
      'mobileButton',
      'businesses',
      'currentCity'
    ])
  },
  components: {
    SvgIcon
  },
  data() {
    return {
      metroOpen: false,
      metro_search: "",
      metroMapSPB: metroMapSPB
    }
  },
  methods: {
    ...mapActions([
      'setParams',
      'resetMetroStations',
      'toggleMetroStationsDesktop',
      'reloadBusinesses'
    ]),
    resetSelectedMetro() {
      this.resetMetroStations()
    }
  }
};
</script>
