<template>
  <div>
    <div class="filter__border-box block my-5 filter__visible" :class="{ active: openFilter }">
      <div class="d-flex pb-3 pl-2">
        <div class="filter__icon">
          <!-- <div v-if="!params.category" @click="openCategories" class="filter__icon"> -->
          <SvgIcon icon="filter" :classes="'mr-2'" />
        </div>
        <div class="filter__title text-left pl-4">Фильтры</div>
      </div>
      <!-- <div class="filter__title" @click="openCategories">{{ params.category ? 'Сфера' : 'Фильтры' }}</div> -->
      <div class="rounded bg-white filter__visible-box">
        <div class="filter__box">
          <div class="filter__label" v-if="params.category" @click="openCategories">Сфера</div>
          <div class="d-flex filter__border">
            <div @click="openCategories" class="filter__select">
              {{ params.category ? params.category.name : 'Выберите сферу бизнеса' }}
            </div>
            <button class="pl-4 pr-2" v-if="!params.category">
              <i class="icon-vector"></i>
            </button>

            <button v-if="params.category" class="pl-4 pr-2" @click="setParams(['subcategory', null ]);setParams(['category', '']);openPage()">
              <i class="icon-close"></i>
            </button>
          </div>
        </div>
        <div v-if="params.subcategory || params.category && params.category.children.length" class="filter__box">
          <div class="filter__label" @click="openSubcategories(params.category)">Категория</div>
          <div class="d-flex filter__border">
            <div @click="openSubcategories(params.category)" class="filter__select">
              {{ params.subcategory ? params.subcategory.name : 'Выберите категорию' }}
            </div>
            <button class="pl-4 pr-2" v-if="!params.subcategory">
              <i class="icon-vector"></i>
            </button>
            <button v-if="params.subcategory" class="pl-4 pr-2" @click="setParams(['subcategory', null ]);openPage()">
              <i class="icon-close"></i>
            </button>
          </div>
        </div>
        <div class="filter__box" v-if="params.category">
          <div class="filter__label">Цена</div>
          <div class="filter__price">
            <div class="filter__select" @click="priceFromModal = true">
              <span class="filter__label">от:</span>
              <span class="currency">
                {{ params.price_from }}
                <!-- <span class="currency">₽</span> -->
              </span>
            </div>
            <div class="filter__select" @click="priceToModal = true">
              <span class="filter__label">до:</span>
              <span class="currency">
                {{ params.price_to }}
                <!-- <span class="currency">₽</span> -->
              </span>
            </div>
          </div>
        </div>
        <div class="filter__box">
          <div class="mb-3">
            <popup>
              <template v-slot:label class="filter__label">Прибыль</template>
              <template v-slot:inputs_value>
                <template v-if="params.income_from">
                  <div class="col d-flex justify-content-between px-0">
                    <span class="popup__label" v-if="params.income_from">от:</span>
                    {{ toLocaleString(params.income_from) }}
                  </div>
                  <button class="filter__reset" @click="resetParams">
                    <i class="icon-close"></i>
                  </button>
                </template>
                <template v-else>
                  Не выбрана
                  <button class="pl-4 pr-2">
                      <i class="icon-vector"></i>
                  </button>
                </template>
              </template>
              <template v-slot:inputs>
                <label class="filter__label">от</label>
                <input class="filter__input" inputmode="numeric" maxlength="10" :value="params.income_from" @change="setParamsAndReloadBusinessesSize(['income_from', $event.target.value])" />
              </template>
            </popup>
          </div>

        </div>
        <div class="filter__box">
          <div class="d-flex justify-content-between">
            <SimpleSelect name="payback" human-name="Окупаемость" :change="updateSelectParams" :value="params.payback_from + '-' + params.payback_to">
            </SimpleSelect>
            <SimpleSelect name="age" human-name="Возраст" :change="updateSelectParams" :value="params.age_from + '-' + params.age_to">
            </SimpleSelect>
          </div>
        </div>
        <MobileMetro></MobileMetro>
      </div>
      <i class="icon-bubble"></i>
      <button class="btn-bubble" type="button" @click="openFilter = !openFilter">
        <svg id="arrow" viewBox="0 0 11 7">
          <path d="M0.488863 0.63072L0.816863 0.30072C0.919863 0.19772 1.05686 0.14072 1.20386 0.14072C1.35086 0.14072 1.48786 0.19772 1.59086 0.30072L5.32686 4.03772L9.06786 0.296719C9.17086 0.193719 9.30786 0.136719 9.45386 0.136719C9.59986 0.136719 9.73786 0.193719 9.84086 0.296719L10.1689 0.623719C10.3819 0.836719 10.3819 1.18372 10.1689 1.39772L5.71486 5.86772C5.61186 5.97072 5.47486 6.04272 5.32686 6.04272L5.32486 6.04272C5.17786 6.04272 5.04086 5.96972 4.93786 5.86772L0.487864 1.40972C0.385863 1.30672 0.328863 1.16572 0.328863 1.01872C0.328863 0.87272 0.385863 0.73372 0.488863 0.63072Z" fill="#fff"/>
        </svg>
      </button>
    </div>
    <transition name="slide-fade">
      <div v-if="categoryOpen" class="filter filter--full">
        <div class="filter__header ">
            <button class="close-filter" @click="closeCategories()"><i class="icon-back"></i></button>
            <div class="filter__title">Сферы</div>
          </div>
        <div class="filter__container">
          <div class="">
            <div v-for="category in topCategories" :key="category.id" :class="{active: category == params.category}" @click="openSubcategories(category)" class="filter__option">
              {{ category.name }}
            </div>
          </div>
          <div v-for="category in categories" :key="category.id" :class="{active: category == params.category}" @click="openSubcategories(category)" class="filter__option">
            {{ category.name }}
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div v-if="subcategoryOpen" class="filter filter--full">
        <div class="filter__header ">
            <button class="close-filter" @click="subcategoryOpen = false">
              <i class="icon-back"></i>
            </button>
            <div class="filter__title">Категории</div>
        </div>
        <div class="filter__container">
          <div v-for="category in params.category.children" :class="{active: category == params.subcategory}" :key="category.id" @click="closeSubcategories(category)" class="filter__option">
            {{ category.name }}
          </div>
          <div @click="closeSubcategories(null)" class="filter__option">
            Все категории
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div v-if="priceToModal" class="filter filter--full">
        <div class="filter__header ">
          <button class="close-filter" @click="priceToModal = false">
            <i class="icon-back"></i>
          </button>
          <div class="filter__title">Стоимость</div>
        </div>
        <div class="filter__container">
          <div v-for="price in priceToList" :class="{active: price[0] == params.price_to}" :key="price[0]" class="filter__option" @click="setPriceTo(price[0])">
            {{ price[1] }}
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div v-if="priceFromModal" class="filter filter--full">
        <div class="filter__header ">
          <button class="close-filter" @click="priceFromModal = false">
            <i class="icon-back"></i>
          </button>
          <div class="filter__title">Стоимость</div>
        </div>
        <div class="filter__container">
          <div v-for="price in priceFromList" :class="{active: price[0] == params.price_to}" :key="price[0]" class="filter__option" @click="setPriceFrom(price[0])">
            {{ price[1] }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import SvgIcon from "./components/SvgIcon.vue"
import Endpoint from "./api/endpoint"
import MobileMetro from "./components/MobileMetro.vue"
import SimpleSelect from "./components/SimpleSelect.vue"
import Popup from "./components/Popup.vue"


export default {
  name: "BusinessesFilterSmall",
  data() {
    return {
      categoryOpen: false,
      subcategoryOpen: false,
      priceFromModal: false,
      priceToModal: false,
      openFilter: false,
      priceFromList: [
        [300000, '300 000'],
        [500000, '500 000'],
        [1000000, '1 млн.'],
        [2000000, '2 млн.'],
        [3000000, '3 млн.'],
        [4000000, '4 млн.'],
        [5000000, '5 млн.'],
        [null, 'Любая стоимость'],
      ],
      priceToList: [
        [500000, '500 000'],
        [1000000, '1 млн.'],
        [2000000, '2 млн.'],
        [3000000, '3 млн.'],
        [4000000, '4 млн.'],
        [5000000, '5 млн.'],
        [10000000, '10 млн.'],
        [null, 'Любая стоимость'],
      ]
    };
  },
  computed: {
    topCategories() {
      return this.categories.filter(category => {
        return this.popularCategoriesIds.includes(category.id)
      })
    },
    ...mapState([
      'categories',
      'params',
      'popularCategoriesIds',
      'mobileButton'
    ])
  },
  components: {
    SvgIcon,
    MobileMetro,
    Popup,
    SimpleSelect
  },
  methods: {
    ...mapActions([
      'setParams',
      'resetParams',
      'initalizeData'
    ]),
    openCategories() {
      this.categoryOpen = true;
      document.body.classList.toggle('fixed-box')
    },
    closeCategories() {
      this.categoryOpen = false;
      document.body.classList.toggle('fixed-box')
    },
    openSubcategories(category) {
      this.setParams(['category', category])
      if (category.children.length) {
        this.subcategoryOpen = true;
      } else {
        this.closeCategories();
        this.setParams(['subcategory', null])
        this.openPage()
      }
    },
    closeSubcategories(category) {
      if (category) {
        this.setParams(['subcategory', category])
        this.closeCategories();
        this.subcategoryOpen = false;
        this.openPage()
      } else {
        this.subcategoryOpen = false;
        this.closeCategories();
        this.setParams(['subcategory', null])
        this.openPage()
      }
    },
    setPriceTo(price) {
      this.setParams(['price_to', price])
      this.priceToModal = false;
      this.openPage()
    },
    setPriceFrom(price) {
      this.setParams(['price_from', price])
      this.priceFromModal = false;
      this.openPage()
    },
    updateSelectParams(field, value) {
      this.setParams([field + '_from', value.split('-')[0], null])
      this.setParamsAndReloadBusinessesSize([field + '_to', value.split('-')[1]])
    },
    openPage() {
      window.showPreloader()
      window.location.href = Endpoint.getBusinessesFromParamsUrl(this.params)
    }
  }
};

</script>
