import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'next', 'prev']

  static values = {
    mode: String,
    index: { type: Number, default: 0 },
    scrollPerClick: { type: Number, default: 1 }
  }

  connect () {
    this.showNav()
    console.log(this.containerTarget.scrollWidth)
  }

  next () {
    if (this.indexValue < this.containerTarget.children.length - 1) {
      this.indexValue++
    }
    console.log(this.slideTarget)
    this.containerTarget[`scroll${this.scrollMode()}`] += this.childSize()
    this.containerTarget[`scroll${this.scrollMode()}`] += this.scrollSize()
    this.showNav()
  }

  prev () {
    if (this.indexValue > 0) {
      this.indexValue--
    }
    this.containerTarget[`scroll${this.scrollMode()}`] -= this.childSize()
    this.containerTarget[`scroll${this.scrollMode()}`] -= this.scrollSize()
    this.showNav()
  }

  scrollSize () {
    const scrollSize = this.containerGapSize() + this.childSize()
    return scrollSize * this.scrollPerClickValue
  }

  scrollMode () {
    return this.modeValue === 'vertical' ? 'Top' : 'Left'
  }

  containerGapSize () {
    const gap = window.getComputedStyle(this.containerTarget).gap.split(' ')[0]
    return Number(gap.replace(/[^0-9.]/g, ''))
  }

  childSize () {
    const sizeBy = this.modeValue === 'vertical' ? 'offsetHeight' : 'offsetWidth'
    return this.containerTarget.children[0][sizeBy]
  }

  showNav () {
    if (this.indexValue <= 0) {
      this.prevTarget.classList.add('d-none')
      this.prevTarget.classList.remove('d-lg-block')
    } else {
      this.prevTarget.classList.remove('d-none')
      this.prevTarget.classList.add('d-lg-block')
    }
    if (this.indexValue >= this.containerTarget.children.length - 1) {
      this.nextTarget.classList.add('d-none')
      this.nextTarget.classList.remove('d-lg-block')
    } else {
      this.nextTarget.classList.remove('d-none')
      this.nextTarget.classList.add('d-lg-block')
    }
  }
}
