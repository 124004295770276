import Vue from 'vue'
import Vuex from 'vuex'
import Api from '../api/businesses'
import initialParams from './initialParams'
import Metro from './metroStations'

import POPULAR_CATEGORIES from './popularCategories'
import humanParams from './humanParams'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    params: {
      title: '',
      category: '',
      subcategory: '',
      price_to: '',
      price_from: '',
      income_to: '',
      income_from: '',
      payback_from: 0,
      payback_to: 0,
      age_from: 0,
      age_to: 0,
      metro_stations: []
    },
    humanParams,
    businesses: '',
    popularCategoriesIds: POPULAR_CATEGORIES,
    categories: [],
    metro_lines: [],
    metro_stations: [],
    mobileButton: {
      loading: false,
      text: 'Показать весь бизнес'
    },
    businessesSize: 0,
    iconsSprite: '',
    currentCity: ''
  },
  actions: {
    setParams ({ state, commit }, [field, value]) {
      commit('updateParams', [field, value])
    },
    reloadBusinesses ({ state, commit }) {
      Api.getBusinesses((data) => commit('updateBusinesses', data), state.params)
    },
    setParamsAndReloadBusinessesSize ({ state, commit }, [field, value]) {
      commit('updateParams', [field, value])
      commit('updateMobileButton', { loading: true })
      Api.getBusinessesSize((data) => commit('updateMobileButton', Object.assign(data, { loading: false })), state.params)
    },
    setParamsAndReloadBusinesses ({ state, commit }, [field, value]) {
      commit('updateParams', [field, value])
      Api.getBusinesses((data) => commit('updateBusinesses', data), state.params)
    },
    setParamsAndReloadBusinessesRemote ({ state, commit }, [field, value]) {
      commit('updateParams', [field, value])
      Api.getBusinessesRemote((data) => {}, state.params)
    },
    resetParams ({ state, commit }) {
      Object.keys(state.params).forEach(key => {
        commit('updateParams', [key, initialParams[key]])
      })
    },
    resetParamsByKeys ({ state, commit }, keys = null) {
      Object.keys(state.params).forEach(key => {
        if (keys.includes(key)) commit('updateParams', [key, initialParams[key]])
      })
    },
    resetMetroStations ({ state, commit }) {
      commit('updateParams', ['metro_stations', []])
    },
    toggleMetroStations ({ state, commit }, metro) {
      commit('toggleMetro', metro)
      Api.getBusinessesSize((data) => commit('updateMobileButton', Object.assign(data, { loading: false })), state.params)
    },
    toggleMetroStationsDesktop ({ state, commit }, metro) {
      commit('toggleMetro', metro)
      Api.getBusinessesRemote((data) => {}, state.params)
    },
    initalizeData ({ state, commit }) {
      commit('updateCity', gon.current_city)
      commit('updateCategories', gon.filter_data.categories)
      commit('updateMetrolines', gon.filter_data.metro_lines)
      commit('updateIconsSprite', document.querySelectorAll('[data-sprite-url]')[0].dataset.spriteUrl)
      commit('updateMetroStations', Metro.addLines(gon.filter_data.metro_lines))

      if (!Utils.objectIsEmpty(gon.params)) {
        Object.keys(gon.params).forEach(key => {
          if (key == 'metro_stations') {
            const selectedStations = state.metro_stations.filter(m => gon.params[key].split('-').includes(m.id.toString()))
            commit('updateParams', [key, selectedStations])
          } else {
            commit('updateParams', [key, gon.params[key]])
          }
        })
      }

      const slug = gon.current_category
      if (slug.length) {
        state.categories.forEach(cat => {
          if (cat.slug == slug) {
            commit('updateParams', ['category', cat])
          }
          if (cat.children) {
            cat.children.forEach(sub => {
              if (sub.slug == slug) {
                commit('updateParams', ['category', cat])
                commit('updateParams', ['subcategory', sub])
              }
            })
          }
        })
      }
    }
  },
  mutations: {
    updateParams (state, [field, value]) {
      state.params = { ...state.params, [field]: value }
    },
    updateBusinesses (state, businesses) {
      state.businesses = businesses
    },
    updateCity (state, city) {
      state.currentCity = city
    },
    updateCategories (state, categories) {
      state.categories = categories
    },
    updateMetrolines (state, metro_lines) {
      state.metro_lines = metro_lines
    },
    updateMetroStations (state, metro_stations) {
      state.metro_stations = metro_stations
    },
    toggleMetro (state, metro) {
      let stations = state.params.metro_stations
      if (stations.includes(metro)) {
        stations = stations.filter(m => { return m != metro })
        state.params.metro_stations = stations
      } else {
        stations.push(metro)
      }
      state.params.metro_stations = stations
    },
    updateMobileButton (state, mobileButton) {
      state.businessesSize = mobileButton.size
      Object.assign(state.mobileButton, mobileButton)
    },
    updateIconsSprite (state, iconsSprite) {
      state.iconsSprite = iconsSprite
    }
  }
})

const Utils = {
  objectIsEmpty (obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) { return false }
    }
    return true
  }
}
