<template>
  <div>
    <div @click="openFilter()" class="header__search-btn d-lg-none d-flex align-items-center justify-content-center btn btn-main-primary">
      <svg viewBox="0 0 18 18" width="20" height="20" class="">
        <path d="M7.6032 0C3.411 0 0 3.411 0 7.6032C0 11.7954 3.411 15.2064 7.6032 15.2064C11.7954 15.2064 15.2064 11.7954 15.2064 7.6032C15.2064 3.411 11.796 0 7.6032 0ZM7.6032 13.8768C4.1442 13.8768 1.3296 11.0628 1.3296 7.6032C1.3296 4.1436 4.1442 1.3302 7.6032 1.3302C11.0622 1.3302 13.8768 4.1442 13.8768 7.6038C13.8768 11.0634 11.0628 13.8768 7.6032 13.8768ZM17.805 16.8648L12.9726 12.0324C12.7128 11.7726 12.2922 11.7726 12.0324 12.0324C11.7726 12.2922 11.7726 12.7134 12.0324 12.9726L16.8648 17.805C16.995 17.9352 17.1648 18 17.3352 18C17.5056 18 17.6754 17.9352 17.8056 17.805C18.0648 17.5458 18.0648 17.1246 17.805 16.8648Z"/>
      </svg>
    </div>
    <div class="header__search-mobile">
      <div class="d-lg-block d-none">
        <input
          type="search" placeholder="Поиск бизнеса (например: Салон красоты)"
          :value="params.title" @input="search($event.target.value)"
          class="form-control">
        <SvgIcon icon="search" :classes="'header__search-icon'" />
      </div>
      <transition name="fade">
        <div v-if="filterOpen" class="header__search-box">
          <div class="d-flex align-items-center justify-content-end pb-2  d-lg-none">
            <button @click="closeFilter()">Закрыть поиск</button>
            <i class="icon-close"></i>
          </div>
          <div class="d-lg-none">
            <input
              placeholder="Поиск бизнеса"
              :value="params.title" @input="search($event.target.value)"
              class="form-control">
              <SvgIcon icon="search" :classes="'header__search-icon'" />
          </div>
          <div class="container mx-auto flex-wrap header__search_container" v-html="$store.state.businesses"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from "vuex"
import SvgIcon from "./components/SvgIcon.vue"

export default {
  name: "Search",
  data() {
    return {
      filterOpen: false
    };
  },
  beforeMount() {
    this.initalizeData()
  },
  computed: {
    ...mapState([
      'params',
    ]),
  },
  components: {
    SvgIcon
  },
  methods: {
    ...mapActions([
      'setParams',
      'resetParams',
      'initalizeData',
      'setParamsAndReloadBusinesses'
    ]),
    search(value) {
      if (value.length){ this.openFilter() }
      else { this.closeFilter() }
      this.setParamsAndReloadBusinesses(['title', value])
    },
    openFilter() {
      this.filterOpen = true;
      let body = document.body;
      body.style.position = "fixed";
      body.style.height = "100vh";
      body.style.right = "0";
      body.style.left = "0";
    },
    closeFilter() {
      this.filterOpen = false;
      let body = document.body;
      body.style.position = "";
      body.style.height = "";
      body.style.right = "";
      body.style.left = "";
    }
  }
};
</script>
