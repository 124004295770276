<template>
  <svg class="svg-icon" :style="styles" :class="classes">
    <use v-bind:xlink:href="spritePath"></use>
  </svg>
</template>

<script>

export default {
  name: "SvgIcon",
  props: {
    icon: {
      type: String,
      required: true
    },
    styles: {
      type: Object,
      required: false,
    },
    classes: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed:{
    spritePath() {
      return this.$store.state.iconsSprite + '#' + this.icon
    }
  }
}
</script>
