const Metro = {
  addLines: function (metroLines) {
    const metroStations = []
    metroLines.forEach(line => {
      line.stations.forEach(metro => {
        if (this.stations.filter(m => m.name.includes(metro.name))[0]) {
          metro.pos = this.stations.filter(m => m.name.includes(metro.name))[0].pos
          metro.text = this.stations.filter(m => m.name.includes(metro.name))[0].text
          metroStations.push(metro)
        } else {
          metroStations.push(metro)
        }
      })
    })
    return metroStations.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  },

  stations: [
    { pos: 'matrix(1 0 0 1 520 19)', name: 'Парнас' },
    { pos: 'matrix(1 0 0 1 520 62)', name: 'Пр. Просвещения' },
    { pos: 'matrix(1 0 0 1 520 121)', name: 'Озерки' },
    { pos: 'matrix(1 0 0 1 520 162)', name: 'Удельная' },
    { pos: 'matrix(1 0 0 1 520 203)', name: 'Пионерская' },
    { pos: 'matrix(1 0 0 1 520 245)', name: 'Чёрная речка' },
    { pos: 'matrix(1 0 0 1 520 376)', name: 'Петроградская' },
    { pos: 'matrix(1 0 0 1 535 480)', name: 'Горьковская' },
    { pos: 'matrix(1 0 0 1 445 650)', name: 'Невский пр.' },
    { pos: 'matrix(1 0 0 1 475 807)', name: 'Сенная пл.' },
    { pos: 'matrix(1 0 0 1 210 935)', name: 'Технологический ин-т' },
    { pos: 'matrix(1 0 0 1 186 956)', name: 'Технологический ин-т 2' },
    { pos: 'matrix(1 0 0 1 436 1043)', name: 'Фрунзенская' },
    { pos: 'matrix(1 0 0 1 436 1097)', name: 'Московские ворота' },
    { pos: 'matrix(1 0 0 1 436 1151)', name: 'Электросила' },
    { pos: 'matrix(1 0 0 1 436 1204)', name: 'Парк Победы' },
    { pos: 'matrix(1 0 0 1 436 1257)', name: 'Московская' },
    { pos: 'matrix(1 0 0 1 436 1311)', name: 'Звёздная' },
    { pos: 'matrix(1 0 0 1 436 1365)', name: 'Купчино' },
    { pos: 'matrix(1 0 0 1 720 18.7003)', name: 'Девяткино' },
    { pos: 'matrix(1 0 0 1 720 75)', name: 'Гражданский пр.' },
    { pos: 'matrix(1 0 0 1 720 146.6505)', name: 'Академическая' },
    { pos: 'matrix(1 0 0 1 720 200.926)', name: 'Политехническая' },
    { pos: 'matrix(1 0 0 1 720 256.5908)', name: 'Пл. Мужества' },
    { pos: 'matrix(1 0 0 1 720 311.3705)', name: 'Лесная' },
    { pos: 'matrix(1 0 0 1 720 370)', name: 'Выборгская' },
    { pos: 'matrix(1 0 0 1 720 421.3639)', name: 'Пл. Ленина' },
    { pos: 'matrix(1 0 0 1 720 571.431)', name: 'Чернышевская' },
    { pos: 'matrix(1 0 0 1 720 645)', name: 'Пл. Восстания' },
    { pos: 'matrix(1 0 0 1 680 827)', name: 'Владимирская' },
    { pos: 'matrix(1 0 0 1 558 885)', name: 'Пушкинская' },
    { pos: 'matrix(1 0 0 1 158 1025)', name: 'Балтийская' },
    { pos: 'matrix(1 0 0 1 210 1116)', name: 'Нарвская' },
    { pos: 'matrix(1 0 0 1 210 1170)', name: 'Кировский завод' },
    { pos: 'matrix(1 0 0 1 210 1242)', name: 'Автово' },
    { pos: 'matrix(1 0 0 1 210 1295)', name: 'Ленинский пр.' },
    { pos: 'matrix(1 0 0 1 210 1365)', name: 'Пр. Ветеранов' },
    { pos: 'matrix(1 0 0 1 322 188)', name: 'Комендантский пр.' },
    { pos: 'matrix(1 0 0 1 322 247)', name: 'Старая Деревня' },
    { pos: 'matrix(1 0 0 1 322 360)', name: 'Крестовский остров' },
    { pos: 'matrix(1 0 0 1 322 444)', name: 'Чкаловская' },
    { pos: 'matrix(1 0 0 1 322 496)', name: 'Спортивная' },
    { pos: 'matrix(1 0 0 1 230 705)', name: 'Адмиралтейская' },
    { pos: 'matrix(1 0 0 1 338 803)', name: 'Садовая' },
    { pos: 'matrix(1 0 0 1 558 908)', name: 'Звенигородская' },
    { pos: 'matrix(1 0 0 1 633 980)', name: 'Обводный канал' },
    { pos: 'matrix(1 0 0 1 665 1043)', name: 'Волковская' },
    { pos: 'matrix(1 0 0 1 665 1108)', name: 'Бухарестская' },
    { pos: 'matrix(1 0 0 1 665 1173)', name: 'Международная' },
    { pos: 'matrix(1 0 0 1 665 1240)', name: 'Пр. Славы' },
    { pos: 'matrix(1 0 0 1 665 1299)', name: 'Дунайская' },
    { pos: 'matrix(1 0 0 1 665 1365)', name: 'Шушары' },
    { pos: 'matrix(1 0 0 1 110 208)', name: 'Беговая' },
    { pos: 'matrix(1 0 0 1 110 320)', name: 'Новокрестовская' },
    { pos: 'matrix(1 0 0 1 0 530)', name: 'Приморская' },
    { pos: 'matrix(1 0 0 1 45 630)', name: 'Василеостровская' },
    { pos: 'matrix(1 0 0 1 571 696)', name: 'Гостиный двор' },
    { pos: 'matrix(1 0 0 1 720 695)', name: 'Маяковская' },
    { pos: 'matrix(1 0 0 1 890 735)', name: 'Пл. Александра Невского 1' },
    { pos: 'matrix(1 0 0 1 890 755)', name: 'Пл. Александра Невского 2' },
    { pos: 'matrix(1 0 0 1 890 1007)', name: 'Елизаровская' },
    { pos: 'matrix(1 0 0 1 890 1093)', name: 'Ломоносовская' },
    { pos: 'matrix(1 0 0 1 890 1185)', name: 'Пролетарская' },
    { pos: 'matrix(1 0 0 1 890 1275)', name: 'Обухово' },
    { pos: 'matrix(1 0 0 1 890 1360)', name: 'Рыбацкое' },
    { pos: 'matrix(1 0 0 1 410 755)', name: 'Спасская' },
    { pos: 'matrix(1 0 0 1 555 760)', name: 'Достоевская' },
    { pos: 'matrix(1 0 0 1 730 752)', name: 'Лиговский пр.' },
    { pos: 'matrix(1 0 0 1 940 810)', name: 'Новочеркасская' },
    { pos: 'matrix(1 0 0 1 1115 830)', name: 'Ладожская' },
    { pos: 'matrix(1 0 0 1 1115 911)', name: 'Пр. Большевиков' },
    { pos: 'matrix(1 0 0 1 1115 1010)', name: 'Улица Дыбенко' }
  ]
}

export default Metro
