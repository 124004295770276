<template>
  <div>
    <div v-if="metro_stations.length">
      <div class="filter__box border-0">
        <div class="filter__label"  @click="metroOpen = true">Метро</div>
        <div class="d-flex">
          <div @click="metroOpen = true" class="btn-main d-flex px-2 justify-content-between w-100">
            <div>
              <SvgIcon :icon="metroIconName()" :classes="'mr-2 svg-icon-header'" />
              <template v-if="params.metro_stations.length">
                Выбрано ({{ params.metro_stations.length }})
              </template>
              <template v-else>Не выбрана</template>
            </div>
            <button class="pl-4 pr-2" v-if="!params.metro_stations.length">
              <i class="icon-vector"></i>
            </button>
            <button v-if="params.metro_stations.length" class="pl-4 pr-2" @click="resetSelectedMetro">
              <i class="icon-close"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="metroOpen" style="z-index:1100" class="filter filter--full filter--padding m-metro__modal">
        <div class="filter__header mb-0">
            <div class="d-flex align-items-center">
              <button class="close-filter" @click="metroOpen = false">
                <i class="icon-back"></i>
              </button>
              <div class="filter__title">Метро</div>
            </div>
            <button class="filter__reset d-flex align-items-center" v-if="params.metro_stations.length" @click="resetSelectedMetro">
              <p>Сбросить</p>
              <i class="icon-close"></i>
            </button>
        </div>
        <div class="filter__container">
          <div class="filter__row border-0 py-3">
            <div class="filter__col p-0">
              <div
                class="filter__tab"
                @click="metroLineTab = !metroLineTab"
                :class="{ active: !metroLineTab }"
              >
                По алфавиту
              </div>
            </div>
            <div class="filter__col p-0">
              <div
                class="filter__tab"
                @click="metroLineTab = !metroLineTab"
                :class="{ active: metroLineTab }"
              >
                По линиям
              </div>
            </div>
          </div>
          <transition name="modal" v-if="!metroLineTab">
            <div>
              <div class="d-flex">
                <input
                  v-if="!metroLineTab"
                  type="text"
                  class="filter__metro_search pl-3"
                  v-model="metro_search"
                  placeholder="Поиск по названию"
                />
                <button v-if="metro_search != ''" class="" @click="metro_search = ''">
                  <i class="icon-close"></i>
                </button>
              </div>
              <div
                v-for="metro in filteredMetros"
                :key="metro.id"
                @click="toggleMetroStations(metro)"
                class="filter__option d-flex align-items-center filter__option--as_check"
                :class="{ selected: params.metro_stations.includes(metro) }"
              >
                <SvgIcon
                  :icon="metroIconName() + '_clean'"
                  :classes="'mr-1 svg-icon-header'"
                  :styles="{fill: metro.color}"
                />
                {{ metro.name }}
              </div>
            </div>
          </transition>
          <transition name="modal" v-else>
            <div>
              <div v-for="line in metro_lines" :key="line.id">
                <div class="group" v-bind:style="{ color: line.color }">
                  <div class="d-flex align-items-center">
                    <SvgIcon icon="metro_dot"
                      :classes="'mr-1'" 
                      :styles="{fill: line.color}"
                      />
                    <div class="pl-3" style="color: #11141F; font-weight: bold">
                      {{ line.name }}
                    </div>
                  </div>
                  <div class="mb-3">
                    <div
                      v-for="metro in line.stations"
                      :key="metro.id"
                      @click="toggleMetroStations(metro)"
                      class="filter__option filter__option--as_check "
                      :class="{ selected: params.metro_stations.includes(metro) }"
                    >
                      <SvgIcon
                        :icon="metroIconName() + '_clean'"
                        :classes="'mr-1 svg-icon-header'"
                        :styles="{fill: metro.color}"
                      />
                      {{ metro.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="filter__container fixed__box">
          <button :disabled="!businessesSize" @click="metroOpen = false" class="btn-main-primary btn-main-primary--with-arrow w-100">
            Выбрать ({{ params.metro_stations.length }})
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import { mapActions, mapState } from "vuex"
import SvgIcon from "./SvgIcon.vue"

export default {
  name: "MobileMetro",
  computed: {
    filteredMetros() {
      if (this.metro_search.length) {
        return this.metro_stations.filter(metro => {
          return metro.name
            .toUpperCase()
            .includes(this.metro_search.toUpperCase())
        });
      } else {
        return this.metro_stations;
      }
    },
    ...mapState([
      'params',
      'metro_lines',
      'metro_stations',
      'mobileButton',
      'businessesSize'
    ])
  },
  components: {
    SvgIcon
  },
  data(){
    return {
      metroLineTab: false,
      metroOpen: false,
      metro_search: "",
    }
  },
  methods: {
    ...mapActions([
      'setParams',
      'resetParamsByKeys',
      'toggleMetroStations'
    ]),
    resetSelectedMetro(){
      this.resetParamsByKeys('metro_stations')
    },
    cityIsMsk() {
      return window.location.hostname.includes('msk')
    },
    metroIconName() {
      return this.cityIsMsk() ? 'msk_metro' : 'metro'
    }
  }
};
</script>
