import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['active']

  toggle () {
    this.activeTarget.classList.toggle('active')
  }

  hideBroker (event) {
    const card = this.element.querySelector('.broker_card')
    if (!card.contains(event.target) && this.activeTarget.classList.contains('active')) {
      this.activeTarget.classList.remove('active')
    }
  }
}
