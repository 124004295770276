import Vue from 'vue'
import store from './store'
import MobileFilter from './mobileFilter.vue'
import MobileFilterSmall from './mobileSmallFilter.vue'
import CategoryFilter from './filter.vue'
import Search from './searchbox.vue'

function mountApp (element, store, app) {
  new Vue({
    el: element,
    store,
    render: h => h(app)
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const categoryFilterBlock = document.getElementById('businesses_desktop_category_filter')
  const mobileFilterBlock = document.getElementById('businesses_filter')
  const MobileFilterSmallBlock = document.getElementById('businesses_filter_small')
  const SearchBlock = document.getElementById('header_search')

  if (categoryFilterBlock) mountApp(categoryFilterBlock, store, CategoryFilter)
  if (mobileFilterBlock) mountApp(mobileFilterBlock, store, MobileFilter)
  if (MobileFilterSmallBlock) mountApp(MobileFilterSmallBlock, store, MobileFilterSmall)
  if (SearchBlock) mountApp(SearchBlock, store, Search)
})
