export default {
  title: 'Поиск',
  category: 'Сфера',
  subcategory: 'Категория',
  price_to: 'Цена до',
  price_from: 'Цена от',
  income_to: 'Прибыль до',
  income_from: 'Прибыль от',
  payback_from: 'Окупаемость от',
  payback_to: 'Окупаемость до',
  age_from: 'Возраст от',
  age_to: 'Возраст до',
  metro_stations: 'Метро'
}
