import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['list', 'button']
  static values = {
    textOpen: String,
    textHide: String
  }

  connect () {
    if (this.isOverflow(this.listTarget.firstChild) === true) {
      this.buttonTarget.classList.remove('d-none')
    } else {
      this.buttonTarget.classList.add('d-none')
      this.listTarget.classList.add('active')
    }
    this.buttonTarget.firstChild.textContent = this.textOpenValue
  }

  toggle () {
    this.listTarget.classList.toggle('active')
    if (this.listTarget.classList.contains('active') === true) {
      this.buttonTarget.firstChild.textContent = this.textHideValue
    } else {
      this.buttonTarget.firstChild.textContent = this.textOpenValue
    }
  }

  hide (event) {
    if (!this.element.contains(event.target) && !this.listTarget.classList.contains('active')) {
      this.listTarget.classList.add('active')
    }
  }

  isOverflow (el) {
    return el.scrollHeight > el.offsetHeight
  }
}
