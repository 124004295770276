<template>
  <div class="simple-select">
    <select required class="simple-select__input" @change="change(name, $event.target.value)" :value="value">
      <option v-for="option in selectOptions" :value="option.val" :key="option.val">{{option.text}}</option>
    </select>
    <label class="filter__label mb-0 simple-select__label">{{ humanName }}</label>
  </div>
</template>

<script>

export default {
  name: "SimpleSelect",
  props: ["name","change", "options", "value", "humanName"],
  computed: {
    selectOptions(){
      if(this.options && this.options.length) return this.options
      return this.defaultOptions
    }
  },
  data(){
    return {
      defaultOptions: [
        { val: '', text: 'Не выбрана'},
        { val: '0-6', text: 'до 6 месяцев'},
        { val: '0-12', text: 'до 1 года'},
        { val: '0-18', text: 'до 1,5 лет'},
        { val: '0-24', text: 'до 2х лет'},
        { val: '24-0', text: 'более 2х лет'}
      ]
    }
  }
};
</script>
