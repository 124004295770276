<template>
  <div class="header__filter-mob">
    <div @click="openFilter">
      <div class="">
        <div class="">
          <svg width="26" height="18" id="filter" viewBox="0 0 26 19">
            <g clip-path="url(#a)">
              <path d="M26 9.37c-.15.38-.44.52-.84.5h-1.8c-.14.28-.23.55-.39.78-.6.88-1.44 1.3-2.48 1.16a2.47 2.47 0 0 1-2.12-1.68c-.08-.22-.17-.26-.38-.26H.9a1.7 1.7 0 0 1-.43-.04.64.64 0 0 1-.45-.62.65.65 0 0 1 .45-.62c.14-.03.29-.04.43-.04h17.08c.23 0 .33-.05.41-.28a2.62 2.62 0 0 1 2.41-1.69 2.57 2.57 0 0 1 2.43 1.67l.11.3h1.83c.4-.02.69.12.84.5v.32Zm-3.9-.15A1.32 1.32 0 0 0 21.3 8a1.28 1.28 0 0 0-1.42.29 1.32 1.32 0 0 0 .93 2.24 1.32 1.32 0 0 0 1.29-1.31ZM26 15.94c-.15.4-.44.52-.84.52l-9.37-.01c-.2 0-.29.04-.36.24A2.62 2.62 0 0 1 13 18.42a2.57 2.57 0 0 1-2.43-1.73c-.07-.2-.18-.24-.37-.24H.86c-.55 0-.86-.24-.86-.67.01-.43.32-.65.87-.65h9.31c.21 0 .32-.04.4-.26a2.62 2.62 0 0 1 2.43-1.7 2.57 2.57 0 0 1 2.42 1.72c.07.2.17.24.36.24h9.37c.4 0 .69.12.84.5v.31Zm-11.7-.14a1.33 1.33 0 0 0-.8-1.22 1.29 1.29 0 0 0-1.41.27 1.32 1.32 0 0 0 .9 2.26 1.3 1.3 0 0 0 1.2-.8c.07-.17.11-.34.11-.51ZM26 2.79c-.15.38-.44.5-.84.5H8.03c-.22 0-.32.04-.4.26a2.58 2.58 0 0 1-2.43 1.7 2.57 2.57 0 0 1-2.43-1.69l-.1-.27H.71c-.55 0-.87-.5-.63-.96.14-.27.38-.36.66-.36H2.6c.24-.4.41-.78.68-1.09a2.56 2.56 0 0 1 4.36.86c.07.2.17.24.36.24l17.16-.01c.4 0 .69.12.84.5v.32ZM6.5 2.63a1.32 1.32 0 0 0-.8-1.2 1.28 1.28 0 0 0-1.41.28 1.32 1.32 0 0 0 .9 2.24 1.3 1.3 0 0 0 1.21-.82c.07-.16.1-.33.1-.5Z"/>
            </g>
            <defs>
              <clipPath id="a">
                <path d="M0 0h26v18.42H0z"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="filterOpen" class="filter filter--full">
        <div class="filter__header">
          <div class="d-flex align-items-center">
            <button class="p-3" @click="closeFilter">
              <i class="icon-close"></i>              <!-- <i class="icon-back"></i> -->
            </button>
            <div class="filter__title">Фильтр</div>
          </div>
          <button class="filter__reset d-flex align-items-center" @click="resetParams">
            <span>Сбросить</span>
            <!-- <i class="icon-close"></i> -->
          </button>
        </div>
        <div class="filter__container">
          <div class="">
            <div class="filter__box">
              <div class="filter__label" @click="openCategories">Сфера</div>
              <div class="d-flex">
                <div v-if="params.category" @click="openCategories" class="filter__select">
                  {{ params.category.name }}
                </div>
                <div v-else class="filter__select" @click="openCategories">Выберите сферу</div>
                <button class="pl-4 pr-2" v-if="!params.category">
                  <i class="icon-vector"></i>
                </button>
                <button v-if="params.category" class="pl-4 pr-2" @click="setParams(['category', '']);setParamsAndReloadBusinessesSize(['subcategory', ''])">
                  <i class="icon-close"></i>
                </button>
              </div>
            </div>
          </div>
            <div class="filter__box" v-if="params.subcategory || params.category && params.category.children.length">
              <div class="filter__label" @click="openSubcategories(params.category)">Категория</div>
              <div class="d-flex">
                <div @click="openSubcategories(params.category)" v-if="params.subcategory" class="filter__select"> {{ params.subcategory.name }} </div>
                <div @click="openSubcategories(params.category)" v-else class="filter__select">Выберите категорию</div>
                <button class="pl-4 pr-2" v-if="!params.subcategory">
                  <i class="icon-vector"></i>
                </button>
                <button v-if="params.subcategory" class="pl-4 pr-2" @click="setParamsAndReloadBusinessesSize(['subcategory', ''])">
                  <i class="icon-close"></i>
                </button>
              </div>
          </div>
          <popup>
            <template v-slot:label class="filter__label">Цена</template>
            <template v-slot:inputs_value>
              <template v-if="params.price_from || params.price_to">
                <div class="col d-flex justify-content-between px-0 mr-4 align-items-center">
                  <span class="popup__label" v-if="params.price_from">от:</span>
                  <span>{{ toLocaleString(params.price_from) }}</span>
                  <button class="filter__reset" @click="resetParams">
                    <i class="icon-close"></i>
                  </button>
                </div>
                <div class="col d-flex justify-content-between px-0 align-items-center">
                  <span class="popup__label" v-if="params.price_to">до:</span>
                  <span>{{ toLocaleString(params.price_to) }}</span>
                  <button class="filter__reset" @click="resetParams">
                    <i class="icon-close"></i>
                  </button>
                </div>
              </template>
              <template v-else>
                Не выбрана
                <button class="pl-4 pr-2">
                  <i class="icon-vector"></i>
                </button>
              </template>
            </template>
            <template v-slot:inputs>
              <label class="filter__label">Цена от</label>
              <input class="filter__input" inputmode="numeric" maxlength="15" :value="params.price_from" @change="setParamsAndReloadBusinessesSize(['price_from', $event.target.value])" />
              <label class="filter__label">Цена до</label>
              <input class="filter__input" inputmode="numeric" maxlength="15" :value="params.price_to" @change="setParamsAndReloadBusinessesSize(['price_to', $event.target.value])" />
            </template>
          </popup>
          <div class="mb-3">
            <popup>
              <template v-slot:label class="filter__label">Прибыль</template>
              <template v-slot:inputs_value>
                <template v-if="params.income_from">
                  <div class="col d-flex justify-content-between px-0">
                    <span class="popup__label" v-if="params.income_from">от:</span>
                    {{ toLocaleString(params.income_from) }}
                  </div>
                  <button class="filter__reset" @click="resetParams">
                    <i class="icon-close"></i>
                  </button>
                </template>
                <template v-else>
                  Не выбрана
                  <button class="pl-4 pr-2">
                      <i class="icon-vector"></i>
                  </button>
                </template>
              </template>
              <template v-slot:inputs>
                <label class="filter__label">от</label>
                <input class="filter__input" inputmode="numeric" maxlength="10" :value="params.income_from" @change="setParamsAndReloadBusinessesSize(['income_from', $event.target.value])" />
              </template>
            </popup>
          </div>
          <div class="d-flex justify-content-between">
            <SimpleSelect name="payback" human-name="Окупаемость" :change="updateSelectParams" :value="params.payback_from + '-' + params.payback_to">
            </SimpleSelect>
            <SimpleSelect name="age" human-name="Возраст" :change="updateSelectParams" :value="params.age_from + '-' + params.age_to">
            </SimpleSelect>
          </div>
          <MobileMetro></MobileMetro>
          <button @click="openPage" v-if="!mobileButton.loading" class="btn-main-primary btn-main-primary--with-arrow my-3 mx-auto">
            {{ mobileButton.text }}
          </button>
          <button disabled v-else class="filter__btn--fixed filter__btn mt-3 ml-auto mr-auto">
            Загрузка...
          </button>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div v-if="categoryOpen" class="filter filter--full">
        <div class="filter__header">
          <div class="d-flex align-items-center">
            <button class="close-filter" @click="closeCategories()">
              <i class="icon-back"></i>
            </button>
            <div class="filter__title">Сферы</div>
          </div>
          <button class="filter__reset d-flex align-items-center" @click="resetParams">
            <span>Сбросить</span>
            <i class="icon-close"></i>
          </button>
        </div>
        <div class="filter__container">
          <div class="">
            <div v-for="category in topCategories" :key="category.id" :class="{active: category == params.category}" @click="openSubcategories(category)" class="filter__option">
              {{ category.name }}
            </div>
          </div>
          <div v-for="category in categories" :key="category.id" :class="{active: category == params.category}" @click="openSubcategories(category)" class="filter__option">
            {{ category.name }}
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div v-if="subcategoryOpen" class="filter filter--full">
        <div class="filter__header ">
          <div class="d-flex align-items-center">
            <button class="close-filter" @click="closeSubcategories(null)">
              <i class="icon-back"></i>
            </button>
            <div class="filter__title">Категории</div>
          </div>
          <button class="filter__reset d-flex align-items-center" @click="resetParams">
            <span>Сбросить</span>
            <i class="icon-close"></i>
          </button>
        </div>
        <div class="filter__container">
          <div v-for="category in params.category.children" :class="{active: category == params.subcategory}" :key="category.id" @click="closeSubcategories(category)" class="filter__option">
            {{ category.name }}
          </div>
          <div @click="closeSubcategories(null)" class="filter__option">
            Все категории
          </div>
        </div>
      </div>
    </transition>
    <button class="filter__sticky_btn" @click="openFilter">Параметры</button>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex"

import Endpoint from "./api/endpoint"

import SimpleSelect from "./components/SimpleSelect.vue"
import Popup from "./components/Popup.vue"
import FindById from "./components/FindById.vue"
import MobileMetro from "./components/MobileMetro.vue"


export default {
  name: "BusinessesFilter",
  data() {
    return {
      filterOpen: false,
      categoryOpen: false,
      subcategoryOpen: false,
    };
  },
  components: {
    Popup,
    SimpleSelect,
    FindById,
    MobileMetro
  },
  computed: {
    topCategories() {
      return this.categories.filter(category => {
        return this.popularCategoriesIds.includes(category.id)
      })
    },
    ...mapState([
      'categories',
      'params',
      'popularCategoriesIds',
      'mobileButton'
    ])
  },
  methods: {
    ...mapActions([
      'setParams',
      'setParamsAndReloadBusinessesSize',
      'resetParams',
      'initalizeData'
    ]),
    updateSelectParams(field, value) {
      this.setParams([field + '_from', value.split('-')[0], null])
      this.setParamsAndReloadBusinessesSize([field + '_to', value.split('-')[1]])
    },
    toLocaleString(number) {
      let num = number
      num = parseInt(num.toString().replace(/\s+/g, ''))
      if (Number.isInteger(num)) {
        return num.toLocaleString('ru-RU')
      } else {
        return ''
      }
    },
    formatNumber(number_type, key) {
      return this.toLocaleString(this[number_type][key])
    },
    toggleSelectMetro(metro) {
      if (metro.selected) {
        this.params.metro_stations = this.params.metro_stations.filter(el => {
          return el.id != metro.id
        })
      } else {
        this.params.metro_stations.push(metro)
      }
    },
    openFilter() {
      this.filterOpen = true
      document.body.classList.add('fixed-box')
    },
    closeFilter() {
      this.filterOpen = false
      document.body.classList.remove('fixed-box')
    },
    openCategories() {
      this.categoryOpen = true
    },
    closeCategories() {
      this.categoryOpen = false
    },
    openSubcategories(category) {
      this.setParamsAndReloadBusinessesSize(['category', category])
      if (category.children.length) {
        this.subcategoryOpen = true
      } else {
        this.closeCategories()
        this.setParams(['subcategory', null])
      }
    },
    closeSubcategories(category) {
      if (category) {
        this.setParamsAndReloadBusinessesSize(['subcategory', category])
        this.closeCategories()
        this.subcategoryOpen = false
      } else {
        this.subcategoryOpen = false
        this.closeCategories()
        this.setParams(['subcategory', null])
      }
    },
    openPage() {
      window.showPreloader()
      window.location.href = Endpoint.getBusinessesFromParamsUrl(this.params)
    }
  }
};

</script>
