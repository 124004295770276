import { Controller } from '@hotwired/stimulus'
import * as tocbot from 'tocbot'

export default class extends Controller {
  static values = { element: String, content: String, heading: String }

  connect () {
    this.generateHeadersIds()

    tocbot.init({
      tocSelector: this.elementValue,
      contentSelector: this.contentValue,
      headingSelector: this.headingValue,
      hasInnerContainers: true
    })
  }

  generateHeadersIds () {
    const content = document.querySelector(this.contentValue)
    const headings = content.querySelectorAll(this.headingValue)
    const headingMap = {}

    headings.forEach((heading) => {
      const id = heading.id
        ? heading.id
        : heading.textContent
          .trim()
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, '-')
          .replace(/(^-|-$)/g, '')
      headingMap[id] = !isNaN(headingMap[id]) ? ++headingMap[id] : 0
      if (headingMap[id]) {
        heading.id = id + '-' + headingMap[id]
      } else {
        heading.id = id
      }
    })
  }
}
