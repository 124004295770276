import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['card']
  static values = {
    media: String,
    visible: Number
  }

  connect () {
    this.isMedia()
  }

  isMedia () {
    const mediaQuery = window.matchMedia(this.mediaValue)
    const card = this.cardTarget
    const visible = this.visibleValue
    window.onscroll = function () {
      if (mediaQuery.matches) {
        if (window.scrollY >= visible) {
          card.classList.add('visible')
        } else {
          card.classList.remove('visible')
        }
      } return false
    }
  }
}
